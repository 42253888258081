import React, { Component } from 'react';
import Metadata from './project_metadata.js'
import Carousel from '../../carousel/'
import { Wrapper, Parallax, ParallaxLayer, ProjectList, Project, Media, ImageBin, ProjectTitle, Notes, Note } from './styles';

class Projects extends Component {
  constructor(props) {
    super(props);
    this.parallaxElement = null;
    this.handleScroll = this.handleScroll.bind(this);
    this.slideChangeCallBack = this.slideChangeCallBack.bind(this);
    this.setParallaxParent = element => {
      this.parallaxElement = element;
    }

    this.state = {
      animationOn : true,
      background1Pos: 0,
      background2Pos: 0,
      background3Pos: 0
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      window.requestAnimationFrame(this.handleScroll);
    });
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', () => {
      window.requestAnimationFrame(this.handleScroll);
    });
  };

  render() {
    const projectElements = Metadata.map((project, index) => {
      const projectTitle = (project.url !== null) ? 
      (<a target='_blank' rel='noopener noreferrer' href={project.url}><ProjectTitle>{project.title}</ProjectTitle></a>)
      : (<ProjectTitle>{project.title}</ProjectTitle>);
      return (
        <Project>
          <Media>
            <ImageBin>
              <img src={project.image} alt={''} />
            </ImageBin>
          </Media>
          <p>
            { /* <Date>{project.date}</Date> */ }
            {projectTitle}
            <Notes>
              {project.notes.map((note, i) => (
                <Note key={`${project.title}-note-${i}`}>{note}</Note>
              ))}
            </Notes>
          </p>
        </Project>
      )
    });

    return (
    <Wrapper ref={this.setParallaxParent}>
      <Parallax data-animation-on={this.state.animationOn}>
        <ParallaxLayer imageURL={'/images/space/space_1.png'} animationDuration={'1'} style={{transform: `translate3d(${this.state.background1Pos}%, 0, 0)`}} />
        <ParallaxLayer imageURL={'/images/space/space_2.png'} animationDuration={'1.3'} style={{transform: `translate3d(${this.state.background2Pos}%, 0, 0)`}} />
        <ParallaxLayer imageURL={'/images/space/space_3.png'} animationDuration={'1.6'} style={{transform: `translate3d(${this.state.background3Pos}%, 0, 0)`}} />
      </Parallax>
      <ProjectList>
        <Carousel onSlideChange={this.slideChangeCallBack} children={projectElements} />
      </ProjectList>
    </Wrapper>);
  }

  slideChangeCallBack(carouselProp, carouselState) {
    const distance = (100/carouselState.sliderItems) * carouselState.currentItem
    this.setState ({
      background1Pos: (-1 * (distance/50)).toFixed(2),
      background2Pos: (-1 * (distance/5)).toFixed(2),
      background3Pos: (-1 * distance).toFixed(2)
    });
  }

  handleScroll(event) {
    let doc = document.documentElement;
    let scrollPosY = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

    const positionInfo = this.parallaxElement.getBoundingClientRect();
    const parallaxElement_posY = Math.round((positionInfo.height || this.parallaxElement.offsetHeight) + positionInfo.top);


    if(scrollPosY < positionInfo.top || scrollPosY > parallaxElement_posY) { 
      this.setState({ animationOn: false })
    } else {
      this.setState({
        animationOn: true
        // background1Pos: (scrollPosY * -1.001),
        // background2Pos: (scrollPosY * -.3),
        // background3Pos: (scrollPosY * -.1)
      })

    }
  };

}

export default Projects;
