import React, {useState} from 'react';
import {ImageSets} from './data';
import Nav from './nav';
import Modal from './modal';
import IconGiftBox from '../../icon/gift-box/';
import Image from '../../image';
import { GalleryContainer, Collection, CollectionItem, GiftIcon } from './styles.js';

const Gallery = () => {
	const [selectedGallery, selectGallery] = useState(0);
	const [viewerVisible, toggleViewer] = useState(false);
	const [selectedItem, setSelectedItem] = useState(0);

	const showModal = (index) => () => { 
		toggleViewer(true);
		setSelectedItem(index);
	};
	const hideModal = () => { toggleViewer(false); };

	const navItems = ImageSets.map(set => set.title);

	const collectionItems = ImageSets[selectedGallery].images.map((image, index) => {
		const imageURL = image.url.replace(/.([^.]*)$/, '_thumb.$1') 
		return (
			<CollectionItem hasProduct={image.productUrl? true : false} orientation={image.orientation} index={index} onClick={showModal(index)} key={`image-${selectedGallery}-${index}`}>
				<figure>
					<Image alt={image.title} src={imageURL} overlay={image.productUrl && <GiftIcon><IconGiftBox /></GiftIcon>} />
				</figure>
			</CollectionItem>
		);
	})

	return (
		<GalleryContainer>
			<Nav clickCallBack={(index)=>{selectGallery(index)}}>
				{ navItems }
			</Nav>
			<Collection>
				{collectionItems}
			</Collection>
			<Modal collection={ImageSets[selectedGallery].images} selectedSlide={selectedItem} isVisible={viewerVisible}  hideModal={ hideModal} />
		</GalleryContainer>
	);
}

export default Gallery;
