import React, { Component } from 'react';
import Title from '../../title/'
import { VideoWrapper, VideoContainer, VideoContent, VideoCredit, VideoPlayer, VideoCover } from './styles';

class Animation extends Component {
	constructor(props) {
		super(props);
		this.play = this.play.bind(this);

		this.video = null;
	    this.setVideo = element => {
	      this.video = element;
	    }

		this.state = {
			initiated : false
		}
	}

	componentDidMount() {
	}

	render() {
		return (
		<VideoWrapper>
			<Title title={'Animation'} />
			<VideoContent>
				<p>
					<VideoCredit>
						<h4>Animation Mentor</h4>
						<h5>Character Animation</h5>
					</VideoCredit>
					<VideoCredit>
						<h4>iAnimate</h4>
						<h5>Feature Workshop 4<br /> with Ted Ty</h5>
					</VideoCredit>
				</p>
				<VideoContainer data-initiated={this.state.initiated}>
					<VideoPlayer ref={this.setVideo} controls="on">
						<source src="media/video/demo_reel.mp4" type="video/mp4" />
						Your browser does not support the video tag. 
					</VideoPlayer>
					<VideoCover onClick={this.play} />
				</VideoContainer>
			</VideoContent>
		</VideoWrapper>);
	}

	play() {
		this.video.play();
		this.setState({
			initiated : true
		});
	};
}

export default Animation;
