import styled from 'styled-components';

export const Wrapper = styled.div`
	margin: 3em 0;
`;

export const Icon = styled.div`
    position: relative;
    margin: 0 auto 5px auto;
    left: 3px;

    &:before,
    &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
    }

    &:before {
        left: 2px;
        border-bottom: 20px solid #9AB;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
    }
    &:after {
        left: 12px;
        border-bottom: 30px solid #9AB;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
    }
`;

export const Copyright = styled.div`
    display: inline-block;
    position: relative;
    margin-bottom: .25ex;
    font-size: 1em;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 100;
    color: #789;
`;
