import styled from 'styled-components';

export const Content = styled.div`
    margin: 0 20px;

    @media screen and (min-width: 641px) {
        max-width: 1000px;
    }

    @media screen and (min-width: 1000px) {
        margin: 0 auto;
    }
`;
