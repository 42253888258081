import React, { Component } from 'react';
// import Navigation from '../navigation';
// import Sketches from '../../pages/sketches/';
import Home from '../../pages/home';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Body, GlobalStyles } from './styles';

class Shell extends Component {
  componentDidMount() {
    // google analytics
    if (typeof(window) !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments) }
      gtag('js', new Date());
      gtag('config', 'UA-87821506-2');
    } 
  }
  render() {
    return (
      <Router>
        <HelmetProvider>
          <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-87821506-2" />
          </Helmet>
          <GlobalStyles />
          <Body>
            { /* <Navigation /> */ }
            <Route exact path="/" component={Home} />
            { /* <Route path="/sketches" component={Sketches} /> */ }
          </Body>
        </HelmetProvider>
      </Router>
    );
  }
}

export default Shell;
