const Inktober =
	{ 
		2019 : {
			title: 'Inktober 2019',
			images : [
				// { 
				// 	url : '/media/sketches/2019/inktober_mindless.jpg',
				// 	title: 'Character building',
				// 	description: 'mindless',
				// 	date: ''
				// },
				{ 
					title: 'Ripe',
					url : '/media/sketches/2019/inktober_ripe.jpg',
					description: 'How I eat watermelons',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_ornament.jpg',
					title: 'Ornament',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_catch.png',
					title: 'Catch',
					description: '',
					date: '',
					orientation: 'horizontal',
				},
				{ 
					url : '/media/sketches/2019/inktober_ride.jpg',
					title: 'Ride',
					description: 'Mounted beast',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_sling.jpg',
					title: 'Sling',
					description: 'minor setbacks',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_swing.jpg',
					title: 'Swing',
					description: 'death stroke',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_tasty.jpg',
					title: 'Tasty',
					description: 'Fetch!',
					date: '',
					productUrl: 'https://www.redbubble.com/shop/p/43176994.APR4B',
				},
				{ 
					url : '/media/sketches/2019/inktober_tread.jpg',
					title: 'Tread',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_misfit.jpg',
					title: 'Why the long face?',
					description: 'misfit',
					date: 'October 20, 2019'
				},
				{ 
					url : '/media/sketches/2019/inktober_legend.png',
					title: 'Legend',
					description: 'They say Kitsune would take human form',
					date: 'October 16, 2019'
				},
				{ 
					url : '/media/sketches/2019/inktober_freeze.jpg',
					title: 'Freeze',
					description: 'Hell froze over',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_ancient.jpg',
					title: 'Ancient',
					description: 'ancients',
					date: '',
					productUrl: 'https://www.redbubble.com/shop/p/43360645.6ATOD',
				},
				{ 
					url : '/media/sketches/2019/inktober_dizzy.jpg',
					title: 'dizzy',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_build.jpg',
					title: 'Build',
					description: 'Victor Frankenstein started young',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/inktober_frail.png',
					title: 'Frail',
					description: '',
					date: 'October 10, 2019'
				}
			]
		},
		2018 : {
			title: 'Inktober 2018',
			images : [
				{ 
					url : '/media/sketches/2018/slice.jpg',
					title: 'Slice',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2018/prickly.jpg',
					title: 'Prickly',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2018/chop.jpg',
					title: 'Chop',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2018/muddy.jpg',
					title: 'Muddy',
					description: '',
					date: 'tough muddah',
					productUrl: 'https://www.redbubble.com/shop/p/35111539.GQXYX',
				},
				{ 
					url : '/media/sketches/2018/expensive.jpg',
					title: 'Expensive',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2018/drain.jpg',
					title: 'Drain',
					description: 'Elizabeth Bathory',
					date: ''
				},
				{ 
					url : '/media/sketches/2018/swollen.jpg',
					title: 'Swollen',
					description: 'You swole bro?',
					date: ''
				},
				{ 
					url : '/media/sketches/2018/yumiko.jpg',
					title: 'Poisonous',
					description: 'Yumiko',
					date: ''
				},
			]
		},
		2017 : {
			title: 'Inktober 2017',
			images : [
				{ 
					url : '/media/sketches/2017/inktober_2017-10-31_mask.png',
					title: 'Mask',
					description: '',
					date: 'October 31, 2017'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-29_united.png',
					title: 'United',
					description: '',
					date: 'October 29, 2017'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-28_climb.jpg',
					title: 'Climb',
					description: '',
					date: 'October 27, 2017'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-26_squeak.jpg',
					title: 'Squeak',
					description: 'Mickey had a group costume idea',
					date: 'October 26, 2017',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-25_ship.png',
					title: 'Ship',
					description: 'The head elf immediately sought help upon hearing that all the reindeer had come down with the flu.',
					date: 'October 25, 2017',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-23_juicy.jpg',
					title: 'Juicy',
					description: '',
					date: 'October 23, 2017',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-22_trail.jpg',
					title: 'Trail',
					description: '',
					date: 'October 22, 2017',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-20_deep.jpg',
					title: 'Deep',
					description: '',
					date: 'October 20, 2017'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-14_fierce.jpg',
					title: 'Fierce',
					description: 'Kazaky',
					date: 'October 14, 2017'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-09_screech.jpg',
					title: 'Screech',
					description: 'screech owls',
					date: 'October 10, 2017'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-08_crooked.jpg',
					title: 'Crooked',
					description: '',
					date: 'October 8, 2017',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-02_divided.jpg',
					title: 'Divided',
					description: '',
					date: 'October 8, 2017'
				},
				{ 
					url : '/media/sketches/2017/inktober_2017-10-04_underwater.png',
					title: 'Underwater',
					description: '',
					date: 'October 5, 2017'
				}

			]
		},
		2014 : {
			title: 'Inktober 2014',
			images : [

				{ 
					url : '/media/sketches/2014/inktober_2014-10-30.jpg',
					title: '',
					description: 'Child\'s play',
					date: 'October 30, 2014'
				},
				{ 
					url : '/media/sketches/2014/inktober_2014-10-23.jpg',
					title: '',
					description: 'Long intergalactic day',
					date: 'October 23, 2014'
				},
				{ 
					url : '/media/sketches/2014/inktober_2014-10-17.jpg',
					title: 'Neurotic',
					description: '',
					date: 'October 17, 2014',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/sketches/2014/inktober_2014-10-16.jpg',
					title: 'Opposites',
					description: '',
					date: 'October 16, 2014',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/sketches/2014/inktober_2014-10-14.jpg',
					title: 'Cereal Mascot',
					description: '',
					date: 'October 14, 2014'
				},
				{ 
					url : '/media/sketches/2014/sweetdreams.png',
					title: 'Childhood monsters',
					description: '"Sweet dreams"',
					date: 'October 13, 2014'
				},
				{ 
					url : '/media/sketches/2014/narwhlove.png',
					title: 'Sea creatures',
					description: 'Narwhlove',
					date: 'October 10, 2014',
					productUrl: 'https://www.redbubble.com/shop/p/35112291.M4A2N',
				},
				{ 
					url : '/media/sketches/2014/inktober_2014-10-09.jpg',
					title: 'Pirates vs Ninjas',
					description: '"Fight!... dance break"',
					date: 'October 9, 2014',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/sketches/2014/inktober_2014-10-08.jpg',
					title: 'Sports',
					description: 'Calvinball gets a little heated.',
					date: 'October 8, 2014'
				}
			]
		}
	};

export default Inktober;