import styled from 'styled-components';

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	transition: opacity .3s ease-in;
`;

export const Wrapper = styled.div`
	height: ${props => props.loaded ? 'auto' : '0'};;
	line-height: 0;
	img {
		opacity: ${props => props.loaded ? '1' : '.2'};
		filter: ${props => props.loaded ? 'unset' : 'blur(1px)'};;

		transition: filter .3s ease-in;
	}

	${Overlay} {
		opacity: ${props => props.loaded ? '1' : '0'};
	}
`;
