import React from 'react';
import { IconContainer } from './styles';

const iconGiftBox = () => (
	<IconContainer>
		<svg version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
			enableBackground="new 0 0 1000 1000"
			xmlSpace="preserve">
			<g>
				<path d="M893.8,294.1H655.5c49-32.1,95.4-73.9,100.8-119.5c2.7-22.2-2.5-54.8-42.7-83.9c-22.3-16.1-44.9-24.3-67.2-24.3c-70.9,0-111,79.5-132.7,150.6c-25.8-90.6-75.9-207-161.8-207c-25.1,0-50,9.9-74.3,29.5c-47.8,38.7-52.6,78.9-48.1,105.8c10,60.2,76.3,112,140.1,148.7H106.2c-19.3,0-34.9,15.3-34.9,34.1v186.3c0,18.8,15.6,34.1,34.9,34.1h9.1v407.4c0,18.8,15.6,34.1,34.9,34.1h699.6c19.3,0,35-15.3,35-34.1V548.5h9.1c19.4,0,35-15.3,35-34.1V328.1C928.8,309.3,913.1,294.1,893.8,294.1L893.8,294.1z M858.8,477.2H566.6v-115h292.2V477.2L858.8,477.2z M646.4,134.6c6.9,0,15.7,3.7,25.6,10.9c16,11.6,15.1,18.7,14.9,21.1c-3,24.8-54,63.9-113.4,96.2C591,194.1,619.5,134.6,646.4,134.6L646.4,134.6z M298.5,134.4c-1-5.6-3.4-20.5,23.7-42.4c7.8-6.3,19.3-13.8,29.6-13.8c38.5,0,78,92.3,101.1,182.1C380.3,224.9,305.2,174.6,298.5,134.4L298.5,134.4z M437.1,362.2v115h-296v-115H437.1L437.1,362.2z M185.2,518.1h251.9v403.7H185.2V518.1L185.2,518.1z M566.7,921.8V518.1h248.1v403.7H566.7L566.7,921.8z" />
			</g>
		</svg>
	</IconContainer>
);

export default iconGiftBox;