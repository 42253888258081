
import styled from 'styled-components';

export const GiftLink = styled.a`
	display: inline-block;
    position: absolute;
    bottom: 0;
    right: -48px;
    width: 43px;
    padding: 9px 10px 11px 10px;
    border-radius: 50%;
    border: 2px dotted #999;

    svg {
    	fill: #777;
    }

    &:hover {
    	border: 2px dotted #eee;
    	background: transparent;
    	svg {
    		fill: #fff;
    	}
    }
`;

export const PreImage = styled.img`
	width: 100%;
	border-radius: 10px;
	filter: blur(5px);
	// box-shadow: 20px 20px 30px #bbddee99, -20px -20px 30px #ffffff99;
`;
export const ModalImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	border-radius: 10px;
	box-shadow: 20px 20px 30px #bbddee99, -20px -20px 30px #ffffff99;
	opacity: ${props => { return props.hasLoaded ? 1 : 0}};

	transition: opacity .3s ease-in;
`;


export const ImageContainer = styled.div`
	position: relative;
`;

export const SlideWrapper = styled.div`
	display: flex;
	height: 100%;

	&:before {
		display: block;
		content: '';
	}
	&:after {
		display: block;
		content: '';
	}

`;

export const SlideMain = styled.div`
	width: 80%;
	max-width: ${props => (props.orientation === 'horizontal') ? '600px' : '400px'};
	margin: auto;
	position: relative;

	h4 {
	    display: inline-block;
		opacity: 0;
	    margin-bottom: 18px;
	    width: 100%;
		z-index: -1;
	    padding: 5px 10px;
		font-size: 1.5em;	    
    	line-height: .9em;
		color: #222;

		transition: opacity .4s ease-in .2s;
	}

	figcaption {
		width: 100%;
	    margin-top: -10px;
	    padding: 20px 5px 15px 5px;
	    font-size: .8em;
	    line-height: 1.2em;
	    color: #333;
		opacity: 0;

		transition: opacity .4s ease-in .2s;
	}


	${props => {
			if (props.isFocused) {
				return (`
					h4{
						opacity: 1;
					}
					figcaption {
						opacity: 1;
					}
				`);
			}
		}
	}


	@media screen and (max-width: 640px) {
		width: 75%;
	}
`;
