import React, { Component } from 'react';
import { Wrapper, Icon, Copyright } from './styles'

class Footer extends Component {
	render() {
		let year = new Date();
		return (
			<Wrapper>
				<Copyright>
					<Icon />
						© {year.getFullYear()}
					</Copyright>
			</Wrapper>
		);
	}
}

export default Footer;