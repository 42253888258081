import React, { useState, useRef, useEffect } from 'react';
import { Slider, Viewer, ExitButton} from './styles.js';
import Slide from '../slide';


const Modal = ({ collection, selectedSlide, isVisible, hideModal }) => {
	// Note: there are 2 ways to update the carousel
	// 1. swiping
	// 2. selecting item from collection thumbnails
	// each method must keep the state variable "focusedSlide" updated
	// (1) - afterChange is used to update the focusedSlide after each swipe
	// (2) - this is sent down by the selectedSlide prop which then updates the focusedSlide state via useEffect 

	const [focusedSlide, setFocusedSlide] = useState(selectedSlide);
	const slides = collection ? collection.map((imageObj, index) => <Slide isFocused={index === focusedSlide} key={`id-${index}`} {...imageObj} />) : [];
	const refSlider = useRef(null);

	useEffect(() => {
		setFocusedSlide(selectedSlide);
		refSlider.current.slickGoTo(selectedSlide);
	}, [selectedSlide, isVisible]);

    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      afterChange: (current) => { setFocusedSlide(current) },
    };

	return (
		<Viewer data-visible={isVisible}>
			<Slider ref={refSlider} {...settings}>
				{slides}
			</Slider>
			<ExitButton onClick={hideModal} />
		</Viewer>
	);
};


// Modal.defaultProps = {
// 	url: '/media/sketches/2019/inktober_tasty.jpg',
// 	title: 'INktoker',
// 	description: 'do not do this',
// 	isVisible: true
// }

export default Modal;