import styled from 'styled-components';

export const VideoCredit = styled.div`
    display: inline-block;
    width: 50%;


    @media screen and (min-width: 641px) {
        width: 100%;
    }
`;

export const VideoWrapper = styled.div`
	margin: 0 auto 300px auto;

    p {
        vertical-align: top;
    }
    h3 {
        font-size: 1.2em;
        margin-bottom: 1.5em;
        text-align: center;
    }
    h4 {
        font-size: .9em;
        margin-bottom: .3em;
    }
    h5 {
        font-size: .6em;
        line-height: 1.2em;
        margin-bottom: 2em;    
    }

    @media screen and (min-width: 641px) {
        p {
            display: table-cell;
            width: 50%;
            vertical-align: middle;
            border: 1px solid #111;
            border-right: none;
        }
        h4 {
            font-size: 1.4em;
            margin-bottom: 0;
        }

        h5 {
            font-size: .8em;
            margin-bottom: 3em;
        }
        ${VideoCredit}:last-child h5 {
            margin-bottom: 0;
        }
    }
`;

export const VideoCover = styled.div`
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 1;
	background: #222;
	cursor: pointer;
	transition: opacity 1s ease-in, top 0s linear 1s;

    /* play button */
    &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(-15px, -50%);
        border-left: solid 45px #666;
        border-bottom: solid 30px transparent;
        border-top: solid 30px transparent;
    }
`;

export const VideoContainer = styled.div`
	position: relative;
	overflow: hidden;

    &[data-initiated='true'] ${VideoCover} {
        opacity: 0;
        top: -100%;    
    }
`;

export const VideoPlayer = styled.video`
	width: 100%;
	margin-top: 1em;
	background: #afa;


    @media screen and (min-width: 641px) {
        display: table-cell;
        margin-top: 0;    
    }
`;

export const VideoContent = styled.div`
    @media screen and (min-width: 641px) {
        display: table;
    }
`;