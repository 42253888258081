import styled, { keyframes } from 'styled-components';

const slide = keyframes`
    0% { transform: translate3d(0%,0,0); }
    100% { transform: translate3d(-100%,0,0); }
`;

const fadeIn = keyframes`
    0% { opacity: 0; left: 30px ;}
    80% { opacity: .8; left: -5px ;}
    100% { opacity: 1; left: 0px ;}
`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 15% 0;
    margin-bottom: 200px;

    /*overflow-x: hidden;*/
`;
  
export const Parallax = styled.div`
      position: absolute;
      top: 0;
      left: calc((100% - 100vw)/2);
      width: 100vw;
      height: 100%;
      overflow: hidden;


    &:before {
        display: block;
        content: '';
        width: 100%;
        position: absolute;
        top: 0;
        padding-top: 15%;
        background: linear-gradient(rgba(30,52,89,1), rgba(30, 52, 89, 0));
        z-index: 2;
    }
    &:after {
        display: block;
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        padding-top: 15%;
        background: linear-gradient(rgba(30, 52, 89, 0), rgba(30,52,89, 1));
        z-index: 2;
    }


    @media screen and (min-width: 641px) {
        &:before,
        &:after {
            padding-top: 150px;
        }
    }

`;
  
export const ParallaxLayer = styled.div`
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      /*transition: transform .4s cubic-bezier(.73,0,.32,.99);*/
  
      width: fit-content;
      display: flex;
      opacity: .15;
  
      transition: transform .5s cubic-bezier(.73,0,.32,.99);  

      &:before,
      &:after { 
          display: block;
          content: '';
          width: 2000px;
          height: 100%;

          background: url(${props => props.imageURL ? props.imageURL : ''});
          animation: ${slide} ${props => props.animationDuration ? props.animationDuration : '1'}s cubic-bezier(.73,0,.32,.99);

    }
      
`;

export const ProjectList = styled.div`
    margin: auto;

    &:after {
        display: block;
        content: '';
        width: 80%;
        margin: auto;
        border-bottom: 1px solid #777;
    }

    @media screen and (min-width: 641px) {
        &:after {
            display: none;
        }
    }
`;


export const Project = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;


    p {
        padding: 0 0 1em 0;
        text-align: left;
    }

    @media screen and (min-width: 641px) {
        p {
            display: inline-block;
            width: 60%;
            padding: 0 1em 0 2em;
            vertical-align: top;
        }
    }    
`;

export const Media = styled.div`
    position: relative;
    margin-bottom: 40px;

    &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: 20px;
        left: 5%;
        width: 90%;
        height: 90%;
        background: #000;
        border: 1px solid transparent;
        border-radius: 0%;
        filter: blur(10px);
        z-index: -1;
        opacity: .4
    }

    @media screen and (min-width: 641px) {
        display: inline-block;
        width: 40%;
        margin-bottom: 25px;
    
        &:after {
            left: 10%;
        }
    }
`;

export const ImageBin = styled.div`
    position: relative;
    padding-top: 60%;
    overflow: hidden;

    img {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
    }


    @media screen and (min-width: 900px) {
        & {
            padding-top: 50%;
        }
    }
`;

export const Date = styled.div`
    margin-bottom: .5em;
    font-size: .8em;
    font-weight: 100;
    color: #94a5b6;

    @media screen and (min-width: 641px) {
        margin-bottom: 1.5em;
    }
`;
export const ProjectTitle = styled.div`
    margin-bottom: .75em;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2em;

    @media screen and (min-width: 641px) {
        margin-bottom: .45em;
        font-size: 2.2em;
    }
`;

export const Notes = styled.div`
    margin-left: 1em;
    font-size: .8em;
    color: #ddd;

    @media screen and (min-width: 641px) {
        position: relative;
        z-index: -2;
        margin-left: .75em;
    }
`;

export const Note = styled.div`
    display: block;
    opacity: 0;
    position: relative;
    margin-bottom: .5em;
    line-height: 1.2em;

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: .2em;
        left: -.6em;
        height: 1em;
        margin-right: 5px;
        /*border-left: 3px solid #fda590;*/
        border-left: 1px solid #fda590;
    }

    *[data-selected=true] &:nth-child(1) {
        animation: ${fadeIn} .2s ease-in .3s forwards;
    }
    *[data-selected=true] &:nth-child(2) {
        animation: ${fadeIn} .2s ease-in .5s forwards;
    }
    *[data-selected=true] &:nth-child(3) {
        animation: ${fadeIn} .2s ease-in .7s forwards;
    }
    *[data-selected=true] &:nth-child(4) {
        animation: ${fadeIn} .2s ease-in .9s forwards;
    }
    *[data-selected=true] &:nth-child(5) {
        animation: ${fadeIn} .2s ease-in 1.3s forwards;
    }

    @media screen and (min-width: 641px) {
        position: relative;
        display: block;
        line-height: 1.4em;
        margin-bottom: .25em;
    }
`;

// .header	 {
//     font-size: 2em;
// }
// .subheader {
//     margin-bottom: 2em;
// }