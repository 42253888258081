import React, { Component } from 'react';
import { Wrapper, Header, SubHeader } from './styles';

class Title extends Component {
  render() {
  	return (
  		<Wrapper>
		    <Header>{this.props.title}</Header>
		    <SubHeader>{this.props.subtitle}</SubHeader>
  		</Wrapper>
	);
  }
}

export default Title;