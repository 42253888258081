import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
    0% {transform: translateX(50%);}
    100% {transform: translateX(0%)}
`;

export const CarouselWrapper = styled.div`
	position: relative;
`;

export const SliderWrapper = styled.div`
	position: relative;
	width: 80%;
	margin: 0 auto;
	overflow-x: hidden;

    @media screen and (min-width: 641px) {
        width: 90%;
    }
`;

export const Slider = styled.div`
	display: flex;
	align-items: stretch;
	/*width: fit-content;*/
	white-space: nowrap;
	transition: transform .2s ease-in;

	animation: ${slideIn} .75s cubic-bezier(.73,0,.32,.99);
`;

export const SlideItem = styled.div`
	position: relative;
	display: inline-block;
	opacity: 0;
	white-space: normal;
	transition: opacity .5s ease-in;

    &[data-selected='true'] {
        opacity: 1;
    }
    &[data-selected='true']:first-child:before,
    &[data-selected='true']:last-child:after {
        display: none;
    }


    @media screen and (min-width: 641px) {
        margin: 0 auto;
    }
`;



export const CarouselButton = styled.div`
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	width: 15px;
	height: 15px;
	border: 2px solid #dfdfdf;
    border-top: none;
	cursor: pointer;
	transition: border-color .3s; 

    &:hover {
        border-color: #79ece7;
    }


    @media screen and (min-width: 641px) {
        width: 20px;
        height: 20px;
    }
`;

export const CarouselButtonPrev = styled(CarouselButton)`
    left: 0;
    border-right: none;
    transform: rotate(45deg);

    @media screen and (min-width: 641px) {
        left: 6px;
    }
`;

export const CarouselButtonNext = styled(CarouselButton)`
    right: 0;
    border-left: none;	
    transform: rotate(-45deg);

    @media screen and (min-width: 641px) {
        right: 6px;
    }
`;

/*
.carousel .buttonPrev:before,
.carousel .buttonNext:before {
	content: '';
	display: block;
	position: absolute;
	top: 13px;
    width: 10px;
    height: 10px;
	background: #678;
}
.carousel .buttonPrev:before,
.carousel .buttonNext:before {
	transition: background-color .3s; 
}

.carousel .buttonPrev:before {
    left: 3px;
}
.carousel .buttonNext:before {
    right: 3px;
}
*/

/*
.carousel .buttonPrev:hover:before,
.carousel .buttonNext:hover:before {
	background: #79ece7;
}
*/
