
import styled from 'styled-components';
import SlickSlider from 'react-slick';

// eslint-disable-next-line import/no-webpack-loader-syntax
import '!style-loader!css-loader!slick-carousel/slick/slick.css';

export const ExitButton = styled.span`
	display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
	color: #ccc;
	border: 2px solid #f9f9f9;
	background: #f9f9f9;

    &:after {
    	content: 'X';
    	display: inline-block;
    	font: normal 24px Arial;
		line-height: 48px;
    }
    &:hover {
	    color: #ddd;
	    background: #bfbfbf;
		border-color: transparent;
    }
`;

export const Slider = styled(SlickSlider)`
	opacity: 0;
	transition: opacity .3s ease-in;
`;


export const Viewer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	transform: translate3d(0, -100%, 0);
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	line-height: 0;
	opacity: 0;
	transition: background .3s ease-in, transform .4s cubic-bezier(.73,0,.32,.99);

	z-index: 100;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg,#e1e1e1,#ddd);
		opacity: 0;
		transition: opacity 1s;
	}


	.slick-slider,
	.slick-list,
	.slick-track,
	.slick-slide > div {
		height: 100%;		
	}


	&[data-visible='true'] {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	&[data-visible='true']:before {
		opacity: .98;
	}

	&[data-visible='true'] {
		${ExitButton} {
			display: block;
		}

		${Slider} {
			opacity: 1;
			transition: opacity .3s ease-in .7s;
		}
	}
`;

