import styled from 'styled-components';

export const SelectedIndicator = styled.span`
	display: inline-block;
	display: none;
	width: 100px;
	border-bottom: 1px solid #fff;
	position: absolute;
	left: 0;
	bottom: 0;
`;

export const DropdownButton = styled.span`
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	font-size: 12px;
	color: #566;
	background: transparent;

	svg {
		width: 40px;
	    position: absolute;
	    right: 5px;
		fill: #faa;
	}

	@media screen and (min-width: 641px) {
		display: none;
	}
`;

export const NavList = styled.ul`
	position: relative;
	width: 100%;
	margin: auto;

	@media screen and (max-width: 640px) {
		& {
			height: 50px;
			z-index: 10;
			padding-top: 50px;
			border-bottom: 1px solid rgba(255,255,255,.3);
		}

		&:before {
			content: ${props => (props.selectedTitle? `'${props.selectedTitle}'`: `'Collections'`)};
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 50px;
			// width: 100%;
			padding: 15px 0;
			font-family: 'Josefin Sans', sans-serif;
			font-size: 16px;
			line-height: 16px;
			border-bottom: rgba(255, 255, 255, .3);

			background: linear-gradient(180deg, #fc6, #00dcff, #f99);
			background-attachment: fixed;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
	@media screen and (min-width: 641px) {
		& {
			width: 100%;
			display: flex;
		    justify-content: space-evenly;
		}

		&::after {
			content: "";
			background: rgba(255, 255, 255, .3);
			// background: linear-gradient(90deg, #00dcff, #f99, #fc6);
			// background-attachment: fixed;
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
`;

export const NavItem = styled.li`
	display: inline-block;
	font-family: 'Josefin Sans', sans-serif;
	font-size: 16px;
	line-height: 16px;
	position: relative;
	color: #789;
	cursor: pointer;

	// selected text effect 
	&:hover {
		color: #def;
		-webkit-text-fill-color: #def;
	}

	@media screen and (max-width: 640px) {
		& {
			display: block;
			padding: 15px 0;
			height: 50px;
			border-bottom: 1px solid #fff3;
			background: #1D3459fa;
			color: #fff6;

		}

		&:first-child {
			margin-top: 1px;
		}

		&[data-selected="true"] {
			color:#fff;
		}

		${NavList}[data-open="false"] & {
			display: none;
		}
	}

	@media screen and (min-width: 641px) {
		& {
			width: 100%;
			padding: 20px 5px;
		}

		&[data-selected="true"] {
			background: linear-gradient(90deg, #fc6, #00dcff, #f99);
			background-attachment: fixed;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		// hovered list item effect
		&::after {
		  content: "";
		  background: linear-gradient(90deg, #00dcff, #f99, #fc6);
		  background-attachment: fixed;
		  height: 1px;
		  position: absolute;
		  bottom: 0;
		  transition: .16s all 0.025s;
		}

		&::after {
			left: 100%;
			right: 0;
		}

		&:hover ~ li::after {
			left: 0;
			right: 100%;
		}

		&:hover::after {
			height: 5px;
			left: 0;
			right: 0;
		}
	}
`;



