import styled from 'styled-components';

export const LayerContainer = styled.div`
	position: relative;
	/*height: 80vh;*/
	height: 800px;
	/*border-bottom: 10px solid #faa;*/
	background: linear-gradient(#fc6, #F99, #00dcff);
	overflow: hidden;

	z-index: 0; /* hides landscape 1: which is position fixed from appearing on the rest of the pages */
`;

export const Layer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: repeat-x;
  background-position: center 0;
  /*background-size: 200%;*/

  background-image: url(${props => props.imageURL ? props.imageURL : '' });
`;

// .bioWrapper,
export const BioWrapper = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
`;

export const Bio = styled.div`
	display: inline-block;
	position: absolute;
	top: 370px;
	left: 20px;
	text-align: left;

	font-size: .8em;
	color: #1e3459;
`;

export const BioName = styled.div`
	font-size: 2em;
	letter-spacing: 2.4px;
`;

export const BioTitle = styled.div`
	margin-bottom: 5px;
`;

export const BioContact = styled.div`
	line-height: .8em;
	letter-spacing: .4px;

    a {
        display: inline-block;
    }
`;


export const BioLinks = styled.div`
	display: block;
	position: absolute;
	top: 396px;
	left: 20px;
	text-align: left;
	opacity: 0;
	font-size: .8em;
	line-height: .8em;
	letter-spacing: .4px;
    color: #1e3459;
    a {
        display: inline-block;
    }
`;

