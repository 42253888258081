import React, { Component } from 'react';
import { LayerContainer, Layer, BioWrapper, Bio, BioName, BioContact, BioTitle, BioLinks } from './styles';
import {ScrollTo} from '../../../utils/scroll';

class Landscape extends Component {
	constructor(props) {
		super(props);
		this.landscapeElement_height = null;
		this.handleScroll = this.handleScroll.bind(this);
		this.setLandscapeElement = element => {
			const positionInfo = element.getBoundingClientRect();
			this.landscapeElement_height = positionInfo.height || element.offsetHeight;

			this.setState({
				landscape1Pos: this.landscapeElement_height/15,
				landscape2Pos: this.landscapeElement_height/11,
				landscape3Pos: this.landscapeElement_height/11,
				landscape4Pos: this.landscapeElement_height/10,
				landscape5Pos: this.landscapeElement_height/8,
				landscape6Pos: this.landscapeElement_height/6
			})
		}
		this.state = {
			landscape1Pos: 0,
			landscape2Pos: 0,
			landscape3Pos: 0,
			landscape4Pos: 0,
			landscape5Pos: 0
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	};

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	};

	render() {
		return (
		<LayerContainer ref={this.setLandscapeElement}>
			<Layer imageURL={'/images/landscape/landscape_1.png'} style={{transform: `translate3d(0, ${this.state.landscape1Pos}px, 0)`}} />
			<Layer imageURL={'/images/landscape/landscape_2.png'} style={{transform: `translate3d(0, ${this.state.landscape2Pos}px, 0)`}} />
			<BioWrapper>
				<Bio>
					<BioName>daniel ho</BioName>
					<BioTitle>developer | doodler</BioTitle>
					<BioContact>
						<a href="//www.linkedin.com/in/daniel-ho-doodles">linkedin</a> &bull; <a href="//www.instagram.com/semi_ho_made/">instagram</a>
					</BioContact>
				</Bio>
			</BioWrapper>
			<Layer imageURL={'/images/landscape/landscape_3.png'} style={{transform: `translate3d(0, ${this.state.landscape3Pos}px, 0)`}} />
			<Layer imageURL={'/images/landscape/landscape_4.png'} style={{transform: `translate3d(0, ${this.state.landscape4Pos}px, 0)`}} />
			<Layer imageURL={'/images/landscape/landscape_5.png'} style={{transform: `translate3d(0, ${this.state.landscape5Pos}px, 0)`}} />
			<Layer imageURL={'/images/landscape/landscape_6.png'} style={{transform: `translate3d(0, ${this.state.landscape6Pos}px, 0)`}} onClick={() => {ScrollTo(100)}} />
			<BioWrapper>
				<BioLinks>
					<a href="//www.linkedin.com/in/daniel-ho-doodles" target="_blank" rel="noopener noreferrer">linkedin</a> &bull; <a href="//www.instagram.com/semi_ho_made/" target="_blank" rel="noopener noreferrer">instagram</a>
				</BioLinks>
			</BioWrapper>
		</LayerContainer>
		);
	}

	handleScroll(event) {
		let doc = document.documentElement;
		let scrollPosY = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

		if(scrollPosY >= (this.landscapeElement_height)) { return; }
		// landscape1Pos: (this.landscapeElement_height - (scrollPosY* [SPEED] ))/ [DISTANCE],

		this.setState({
			landscape1Pos: Math.round((this.landscapeElement_height - (scrollPosY* -15))/15),
			landscape2Pos: Math.round((this.landscapeElement_height - (scrollPosY* -12))/11),
			landscape3Pos: Math.round((this.landscapeElement_height - (scrollPosY* -9))/11),
			landscape4Pos: Math.round((this.landscapeElement_height - (scrollPosY* -8))/10),
			landscape5Pos: Math.round((this.landscapeElement_height - (scrollPosY* -4))/8)
		})
	};


}

export default Landscape;