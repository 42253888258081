import React, {useState} from 'react';
import DownArrow from '../../../icon/down-arrow';
import {  NavList, NavItem, SelectedIndicator, DropdownButton } from './styles.js';

const Nav = (props) => {
	const [isOpen, toggleMenu] = useState(false);
	const [selectedItem, setSelectedItem] = useState(0);
	const onNavClick = (index, title) => () => {
		setSelectedItem({ index, title });
		props.clickCallBack(index)
		toggleMenu(false);
	}

	const navItems = props.children.map((title, index) => {
		const selected = (index === selectedItem.index)? true : false;

		return (
			<NavItem key={`nav-item${index}`} data-selected={selected} onClick={onNavClick(index, title)}>
				{ title }
			</NavItem>
			);
		}
	);
	return (
		<NavList data-open={isOpen} selectedTitle={selectedItem.title}>
			{ navItems }
			<SelectedIndicator />
			<DropdownButton onClick={()=>{toggleMenu(!isOpen)}}><DownArrow /></DropdownButton>
		</NavList>
	);
}

export default Nav;
