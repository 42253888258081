import styled from 'styled-components';

export const Wrapper = styled.div`
	margin: 0 auto 1em auto;
`;

export const Header = styled.div`
	display: inline-block;
	position: relative;
	margin-bottom: .25ex;
	font-size: 4em;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 100;
    /*color: #9AB;*/

    @media screen and (min-width: 641px) {

        & {
            /*text-align: left;*/
        }
    
    }
`;

export const SubHeader = styled.div`
	color: #789;
`;

