import React, { useState } from 'react';
import { Wrapper, Overlay } from './styles';

export default ({src, alt, overlay}) => {
	const [loaded, setLoaded] = useState('false');
	const handOnLoad = () => { setLoaded('true'); };

	return (
		<Wrapper loaded={loaded} >
			<img src={src} alt={alt} loaded={loaded} onLoad={handOnLoad} />
			<Overlay>{overlay}</Overlay>
		</Wrapper>);
};