const Metadata =
	[
		{ 
			title :'CNNMoney Tech / Media',
			url: 'http://money.cnn.com/technology',
			image : '/media/projects/cnnmoneytech_desktop.png',
			date: '2016, 2017',
			notes: [
				'React, Redux, CSS Modules, Webpack, Node.js',
				'reusable and themed component architecture',
				'progressive image loading, responsive images',
				'parallax component, video playlist'
			]
		},
		{ 
			title :'CNNMoney Switzerland',
			url: 'http://cnnmoney.ch',
			image : '/media/projects/cnnmoneyswitzerland_desktop.png',
			date: '2017',
			notes: [
				'React, CSS Modules, Webpack, Node.js, GraphQL',
				'react components',
				'graphQL: polling, pagination',
				'server-side rendering'
			]
		},
		{ 
			title :'CNNMoney',
			url: 'http://money.cnn.com',
			image : '/media/projects/cnnmoney_desktop.png',
			date: '2007 - present',
			notes: [
				'various stacks: React, CSS Modules, Webpack, Node.js, GraphQL, Freemarker',
				'building site templates',
				'building interactive features',
				'pinned video player'
			]
		},
		{ 
			title :'Teyaari',
			url: null,
			image : '/media/projects/teyaari.png',
			date: '2006 - 2007',
			notes: [
				'PHP, MySQL',
				'site & brand design',
				'event planning vendor database',
				'login / registration'
			]
		}
	];

export default Metadata;