import Inktober from './inktober';

export const ImageFeaturedSet =
	{
		title: '',
		images : [
			{ 
				url : '/media/sketches/2019/kingofhearts.jpg',
				title: 'King of Hearts',
				date: 'March 2019'
			}
		]
	}

export const ImageSets =
	[ 
		{
			title: 'Mermaids',
			images : [
				{
					url : '/media/sketches/2020/mermay_maidmer.png',
					title: '',
					description: 'What? I\'m a mermaid',
					date: '',
				},
				{
					url : '/media/sketches/2020/mermay_hammerhead.png',
					title: '',
					description: 'shark infested waters',
					date: '',
				},
				{
					url : '/media/sketches/2020/mermay_lobster.png',
					title: '',
					description: 'That time when Sebastian went to Ursula',
					date: '',
				},
				{
					url : '/media/sketches/2020/mermay_angler.png',
					title: '',
					description: 'Under the sea',
					date: '',
				},
				{
					url : '/media/sketches/2020/mermay_octopus.png',
					title: '',
					description: 'She had legs for days',
					date: '',
				},
				{
					url : '/media/sketches/2020/mermay_assassin.png',
					title: '',
					description: 'Oil rig hunter',
					date: '',
				},
				{
					url : '/media/sketches/2020/mermay_mantaray.png',
					title: '',
					description: 'They say the manta ray is majestic',
					date: '',
				},

				{ 
					url : '/media/sketches/2019/mermay_crustacean.jpg',
					title: 'Clear waters',
					description: '',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/40003228.GDEN5',
				},
				{ 
					url : '/media/sketches/2019/mermay_yogi.jpg',
					title: 'Calm waters',
					description: '',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/39927763.R05UB',
				},
				{ 
					url : '/media/sketches/2019/mermay_murky.jpg',
					title: 'Murky waters',
					description: '',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/39927825.73HD4',
				},
				{ 
					url : '/media/sketches/2019/mermay_pale.jpg',
					title: 'Deep waters',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/mermay_solemn.jpg',
					title: 'Still waters',
					description: '',
					date: ''
				},
				{ 
					url : '/media/sketches/2019/mermay_cartoony.jpg',
					title: 'Jake and Fish Finn',
					description: 'Someone jumped into an Adventure Time portal!',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/40003360.6R8P0',
				},
				{ 
					url : '/media/sketches/2019/mermay_koi.jpg',
					title: '',
					description: '',
					date: '',
				}
			]
		},
		{
			title: 'Gems',
			images : [
				{ 
					url : '/media/sketches/2019/gem_lion.png',
					title: 'Bood Lion',
					description: 'The Blood Lion, heart of the Savanna',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/38634639.FB110.unisex-tee-w',
				},
				{ 
					url : '/media/sketches/2019/gem_stag.jpg',
					title: 'Fiery Stag',
					description: 'The Fiery Stag, protector of the forest',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/38483269.FB110',
				},
				{ 
					url : '/media/sketches/2019/gem_bear.png',
					title: 'Verdant Bear',
					description: 'The Verdant Bear, guardian of the woodlands',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/38783252.FB110.unisex-tee-w',
				},
				{ 
					url : '/media/sketches/2019/gem_dragon.png',
					title: 'Scorched Dragon',
					description: 'The Scorched Dragon, the fire in the sky.',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/39057474.FB110',
				},
				{ 
					url : '/media/sketches/2019/gem_kraken.png',
					title: 'Crowned Kraken',
					description: 'The Crowned Kraken, sovereign of the seas.',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/38924640.FB110'
				},
				{ 
					url : '/media/sketches/2019/gem_wolf.png',
					title: 'Winter Wolf',
					description: 'The Winter Wolf, protector of the tundra',
					date: '',
					productUrl: '//www.redbubble.com/shop/p/38490626.FB110',
				}
			]
		},
		{
			title: 'Sprites',
			images : [
				{ 
					url : '/media/sketches/2019/leaflet_pose.jpg',
					title: 'Leif - stance',
					description: 'Game character - standing pose.',
					date: 'March 2019'
				},
				{ 
					url : '/media/sketches/2019/leaflet_spritesheet.jpg',
					title: 'Leif - sprite sheet',
					description: 'Game character sprite sheet. Animating frames for walk, jump, and idle poses',
					date: 'March 2019'
				},
				{ 
					url : '/media/projects/fowl_play/chicks_spritesheet.png',
					title: 'Game Characters',
					description: 'The Poultrygeist, the Ugly Duckling, the Pheonix, blue chick, yellow chicks, ',
					date: '',
					orientation: 'horizontal'
				},
				{ 
					url : '/media/projects/fowl_play/tiles_spritesheet.png',
					title: 'Game Tiles',
					description: 'Walls, safe grounds, hatcheries, teleporters, hazardous grounds, and a happiness field',
					date: ''
				}
			]
		},
		{
			title: 'Minis',
			images : [
				{ 
					url : '/media/sketches/2018/lilgandalf.png',
					title: 'Lil Gandalf',
					date: 'May 2018'
				},
				{ 
					url : '/media/sketches/2018/liljaga.png',
					title: 'Lil Jaga',
					date: 'June 2018'
				},
				{ 
					url : '/media/sketches/2018/lilwalterwhite.png',
					title: 'Lil Walter White',
					date: 'May 2018'
				},
				{ 
					url : '/media/sketches/2018/liljonsnow.png',
					title: 'Lil Jon Snow',
					date: 'May 2018'
				}
			]
		},
		Inktober[2019],
		Inktober[2018],
		Inktober[2017],
		Inktober[2014],
		{
			title: 'Misc',
			images : [
				{ 
					url : '/media/sketches/2020/the_little_things.png',
					title: 'Don\'t sweat...',
					description: '',
					date: 'March 2020',
					productUrl: '//redbubble.com/shop/p/45784254.P1QBH',
				},
				{ 
					url : '/media/sketches/2019/francestheferocious.png',
					title: 'Frances, the Ferocious',
					description: 'Frances didn\'t want to be ferocious. He wanted to learn how plants regulate transpiration with their stomata.',
					date: 'March 2019'
				},
				// { 
				// 	url : '/media/sketches/2019/babydaddy.jpg',
				// 	title: 'Giggle machine',
				// 	description: 'Hmmm... what is this giggle machine?',
				// 	date: 'March 2019'
				// },
				{ 
					url : '/media/sketches/2019/hibernation.jpg',
					title: 'Hibernation',
					description: 'Did someone bring crumpets?',
					date: 'March 2019'
				},
				// { 
				// 	url : '/media/projects/teyaari/logo.png',
				// 	title: 'Logo design',
				// 	description: 'Event planning company',
				// 	date: ''
				// },
				// { 
				// 	url : '/media/sketches/2018/rudy.jpg',
				// 	title: 'Rudy',
				// 	description: 'They never let poor Rudy join in any football games',
				// 	date: 'October 2018'
				// },
				{ 
					url : '/media/sketches/2017/theRooster.jpg',
					title: 'The Rooster',
					description: '',
					date: 'February 11, 2017'
				},
				// { 
				// 	url : '/media/sketches/2018/ox.png',
				// 	title: 'The Ox',
				// 	date: 'January 2018'
				// },
				// { 
				// 	url : '/media/sketches/2016/ram.png',
				// 	title: 'The Ram',
				// 	date: 'March 2016'
				// },
				{ 
					url : '/media/sketches/2018/cervitaur.png',
					title: 'Cervitaur',
					date: 'July 2018'
				},
				{ 
					url : '/media/sketches/2018/escher_lifecycle.png',
					title: 'Disoriented',
					description: 'Going through life disoriented',
					date: 'February 2018',
					productUrl: '//www.redbubble.com/shop/p/45055239.DYMRA'
				},
				{ 
					url : '/media/sketches/2018/lettuce-turnip-the-beet.png',
					title: 'Rock out with your stalks out',
					date: 'January 2018',
					orientation: 'horizontal',
					productUrl: '//www.redbubble.com/shop/p/35327707.M4A2N'
				},
				{ 
					url : '/media/sketches/2017/lil_krampus.jpg',
					title: 'lil Krampus',
					description: 'It was in fact little Krampus that wasn\'t allowed in any reindeer games.',
					date: 'December 2017'
				},
				{ 
					url : '/media/sketches/2016/tangled.png',
					title: 'Symmetry',
					date: 'February 2016'
				},
				// { 
				// 	url : '/media/sketches/2015/rainbow_party.jpg',
				// 	title: 'Foodies',
				// 	date: 'September 2015'
				// },
				{ 
					url : '/media/sketches/2014/catcostume.png',
					title: 'Hidden',
					description: 'My happy place',
					date: 'October 6, 2016',
					orientation: 'horizontal',
					productUrl: '//www.redbubble.com/shop/p/35112073.I57B7',
				},
				// { 
				// 	url : '/media/sketches/2015/inktober_10-13-15_seahorse.png',
				// 	title: 'Seahorse Boy band',
				// 	description: '',
				// 	date: 'October 13, 2015',
				// 	orientation: '',
				// },
				// { 
				// 	url : '/media/sketches/2014/revolving_bodies_01.jpg',
				// 	title: 'Revolving Bodies I',
				// 	description: '',
				// 	date: 'Janurary 16, 2014',
				// 	orientation: 'horizontal',
				// },
				{ 
					url : '/media/sketches/2014/revolving_bodies_02.jpg',
					title: 'Revolving Bodies II',
					description: '',
					date: 'February 7, 2014'
				},
			]
		},
	];
