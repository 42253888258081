import React from 'react';
import Landscape from '../../component/shelf/landscape';
import Projects from '../../component/shelf/projects';
import Gallery from '../../component/shelf/gallery';
import Animation from '../../component/shelf/animation';
import Footer from '../../component/footer';
import LazyLoad from 'react-lazyload';
import { Content } from './styles'

const placeholder_height = 400;

const Home = () => (
    <div>
      <Landscape />
      <Content>
        <LazyLoad height={placeholder_height} offset={50}>
          <Gallery />
        </LazyLoad>
        <LazyLoad height={placeholder_height} offset={50}>
            <Projects />
        </LazyLoad>
        <LazyLoad height={placeholder_height} offset={0}>
            <Animation />
        </LazyLoad>
      </Content>
      <Footer />
    </div>
);

export default Home;
