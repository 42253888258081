import React, { Component } from 'react';
import { CarouselWrapper, SliderWrapper, Slider, SlideItem, CarouselButtonNext, CarouselButtonPrev } from './styles';

class Carousel extends Component {
  static defaultProps = {
    onSlideChange: () => {}
  }

  constructor(props) {
    super(props);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.slideRight = this.slideRight.bind(this);
    this.slideLeft = this.slideLeft.bind(this);
    this.startDrag = this.startDrag.bind(this);
    this.stopDrag = this.stopDrag.bind(this);
    this.touchStart = this.touchStart.bind(this);
    this.touchStop = this.touchStop.bind(this);
    this.sliderWidth = 0;

    this.sliderElement = null;

    this.setSlider = element => {
      this.sliderElement = element;
    }

    this.state = {
      sliderItems: 0,
      startDragPos: 0,
      movePos: 0,
      isDragging: false,
      moveDistance: 0,
      sliderDragLimit: 0,
      currentItem: 0,
      swipeDirection: 0 /* pos = right, neg = left*/
    }
  }

  componentDidMount() {
    /* Set slider width */
    this.setSliderLength();
    window.addEventListener("resize", this.recalcDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.recalcDimensions());
  }
  render() {
    const sliderWidth = this.props.children.length * 100;
    const slideItemWidth = (100 / this.props.children.length);
    const children = (this.props.children).map((child, index) => {
      const selectedItem = (index === this.state.currentItem) ? 'true' : '';
      return <SlideItem style={{width: `${slideItemWidth}%`}} data-selected={selectedItem} key={`carousel-${index}`}>{child}</SlideItem>
    });
    return (
      <CarouselWrapper>
        <SliderWrapper onMouseUp={this.stopDrag} onMouseDown={this.startDrag} onMouseMove={this.onMouseMove} onTouchStart={this.touchStart} onTouchEnd={this.touchStop} onTouchMove={this.onMouseMove}>
          <Slider ref={this.setSlider} style={{width: `${sliderWidth}%`, transform: `translate3d(${this.state.moveDistance}px, 0, 0)`}}>
            {children}
          </Slider>
        </SliderWrapper>
        { this.state.currentItem === -1 ? '' : <CarouselButtonPrev onClick={this.slideLeft} /> }
        { (this.state.currentItem+1) === this.state.sliderItems ? '' : <CarouselButtonNext onClick={this.slideRight} /> }
      </CarouselWrapper>
    );
  }

  slideRight() {
    const itemWidth = this.sliderWidth/this.state.sliderItems;
    let currentItem, moveDistance;

    currentItem = this.state.currentItem+1;
    if(currentItem >= this.state.sliderItems) {
      currentItem = 0;
    }

    moveDistance = -1 * currentItem * itemWidth;

    this.setState((prevState, props) => {
     this.props.onSlideChange(this.props, Object.assign({}, this.state, {currentItem: currentItem}));
     return { 
        moveDistance: moveDistance,
        currentItem: currentItem,
        isDragging: false,
        swipeDirection: +1
      }
    });
  }

  slideLeft() {
    const itemWidth = this.sliderWidth/this.state.sliderItems;
    let currentItem, moveDistance;

    currentItem = this.state.currentItem-1;
    if(currentItem < 0) {
      currentItem = this.state.sliderItems - 1;
    }

    moveDistance = -1 * currentItem * itemWidth;


    this.setState((prevState, props) => {
     this.props.onSlideChange(this.props, Object.assign({}, this.state, {currentItem: currentItem}));
     return { 
        moveDistance: moveDistance,
        currentItem: currentItem,
        isDragging: false,
        swipeDirection: -1
      }
    });
  }

  setSliderLength() {
    const numChildren = this.props.children.length || 0;
    const elementInfo = this.sliderElement.getBoundingClientRect();
    this.sliderWidth = elementInfo.width || this.sliderElement.offsetWidth;

    this.setState({
      sliderDragLimit: (this.sliderWidth - (this.sliderWidth/numChildren))*-1,
      sliderItems: numChildren
    })
  }

  getMousePosition(e) {
    e = e || window.event;
    let pageX = e.pageX;
    if (pageX === undefined) {
        /*
          mouse event pos:  e.clientX 
          touch event pos: e.targetTouches[0].clientX 
          */
        pageX = (e.clientX || (e.targetTouches && e.targetTouches[0] && e.targetTouches[0].clientX) || 0);
        pageX += document.body.scrollLeft + document.documentElement.scrollLeft;
    }
    return pageX;    
  }

  stopDrag(event) {
    const directionChange = (this.state.startDragPos - this.state.movePos);

    if(directionChange > 0) {
      this.slideRight();
    } else if(directionChange < 0) {
      this.slideLeft();
    } else {
      this.setState({ 
        isDragging: false 
      });
    }
  }

  startDrag(event) {
    const currentPos = this.getMousePosition(event);
    this.setState({ 
      startDragPos: currentPos,
      movePos: currentPos,
      isDragging: true
    });
  }

  onMouseMove(event) {
    if(!this.state.isDragging) { return }

    const dragPos = this.getMousePosition(event);
    const moveDistance = this.state.moveDistance + (dragPos - this.state.movePos);

    this.setState({
      movePos: dragPos,
      moveDistance: moveDistance
    })
  }

  touchStart(event) {
    event.preventDefault();
    this.startDrag(event);
  }

  touchStop(event) {
    event.preventDefault();
    this.stopDrag(event);
  }

  recalcDimensions(){
    const component = this;
    return () => {
      const itemWidth = component.sliderWidth/component.state.sliderItems;

      component.setSliderLength();

      component.setState({ 
        moveDistance: (-1 * component.state.currentItem * itemWidth)
      });      
    }
  }

}

export default Carousel;
