import React, { useState } from 'react';
import IconGiftBox from '../../../icon/gift-box/';
import { SlideMain, SlideWrapper, GiftLink, ImageContainer, PreImage, ModalImage } from './styles.js';

const Slide = ({ url, title, description, orientation, productUrl, isFocused }) => {
	const [hasLoaded, setHasLoaded] = useState(false);
	const handleOnLoad = () => { 
		setHasLoaded(true);
	};

	return (
		<SlideWrapper>
			<SlideMain orientation={orientation} isFocused={isFocused}>
				{title && <h4 dangerouslySetInnerHTML={{ __html: title }} />}
				<ImageContainer>
					<PreImage src={url} alt='preloade image placeholder' />
					<ModalImage src={url} alt={title} hasLoaded={hasLoaded} onLoad={handleOnLoad} />
					{productUrl && <GiftLink href={productUrl} target="funsies"><IconGiftBox /></GiftLink>}
				</ImageContainer>
				{description && <figcaption dangerouslySetInnerHTML={{ __html: description }} />}
			</SlideMain>
		</SlideWrapper>
	);
};

export default Slide;