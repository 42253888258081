import styled from 'styled-components';

export const GalleryContainer = styled.div`
	position: relative;
	margin-bottom: 200px;
`;


export const Collection = styled.div`
	min-height: 300px;
`;

export const CollectionItem = styled.div`
	display: inline-block;
	margin: 30px 0 0 0;
	position: relative;
	top: 0;
	transition: margin-top .2s ease-in;
	cursor: pointer;
	box-shadow: ${props => (props.hasProduct ? '5px 5px 15px rgba(30, 80, 200, .5)' : '5px 5px 15px rgba(20, 20, 30, .5)')};
	border-radius: 5px;

	transition: top .14s ease-in, box-shadow .14s ease-in;

	figure {
		line-height: 0;
		overflow: hidden;
		img {
			width: 100%;
			border-radius: 5px;
		}
	}

	&:hover {
		z-index: 2;
		top: -15px;
		box-shadow: ${props => 
			props.hasProduct ? '5px 5px 15px rgba(30, 80, 200, .3)' : '5px 5px 15px rgba(20, 20, 30, .3)'};
	}

	@media screen and (max-width: 640px) {
		& {
			width: ${props => props.orientation === 'horizontal' ? '66%' : '33%' };
			figure {
				width: 100%;
			}
		}
	}
	@media screen and (min-width: 641px) {
		figure {
			width: ${props => props.orientation === 'horizontal' ? '260px' : '160px' };
		}
	}
`;

export const GiftIcon = styled.span`
	display: inline-block;
	position: absolute;
	bottom: 4px;
	right: 4px;
	width: 30px;
	height: 30px;
	padding: 5px 6px 7px 6px;
	border: 1px dotted #0007;
	border-radius: 50%;
	background: #aaa3;

	svg {
		fill: #0009;
	}
`;
